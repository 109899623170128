// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/landingPage/GetInvolved/Group 80.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/landingPage/GetInvolved/Mask group.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GetInvolved_section1__IST4A {\n  padding-top: 8.5rem;\n  padding-bottom: 8rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: left 72%;\n}\n\n.GetInvolved_section2__K3lDc {\n  border: 1px solid #D9D9D9;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  border-radius: 25px;\n}\n\n.GetInvolved_section3__mdric {\n  background: linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(2, 0, 0, 0.56)), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n\n@media screen and (max-width: 992px) {\n  .GetInvolved_section1__IST4A {\n    padding-top: 9.5rem;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/landingpage/GetInvolved.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,oBAAA;EACA,yDAAA;EACA,4BAAA;EACA,6BAAA;AACJ;;AACA;EACI,yBAAA;EACA,2CAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,oHAAA;EACA,4BAAA;EACA,sBAAA;AAGJ;;AADA;EACI;IACI,mBAAA;EAIN;AACF","sourcesContent":[".section1 {\n    padding-top: 8.5rem;\n    padding-bottom: 8rem;\n    background-image: url('../../images/landingPage/GetInvolved/Group\\ 80.svg');\n    background-repeat: no-repeat;\n    background-position: left 72%;\n}\n.section2{\n    border: 1px solid #D9D9D9;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 25px;\n}\n.section3{\n    background:linear-gradient(0deg, rgba(.4, .4, .4, .56), rgba(2.4, .4, .4, .56)), url('../../images/landingPage/GetInvolved/Mask group.jpg');\n    background-repeat: no-repeat;\n    background-size: cover;\n  }\n@media screen and (max-width: 992px) {\n    .section1 {\n        padding-top: 9.5rem;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section1": "GetInvolved_section1__IST4A",
	"section2": "GetInvolved_section2__K3lDc",
	"section3": "GetInvolved_section3__mdric"
};
export default ___CSS_LOADER_EXPORT___;
