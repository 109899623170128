import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/news/banner.jpg';
import SaSquared from '../../images/landingPage/news/SaSquared.jpg';
import Section2 from '../../images/landingPage/news/section2.jpg';
import Section5 from '../../images/landingPage/news/section5.jpg';
import Spacepointe from '../../images/landingPage/news/spacepointe.jpg';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function Blog1() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.blogSection2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
                        <div className='position-relative'>
                            <img src={SaSquared} alt='GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>January 3rd, 2023</div>
                        </div>
                       
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">“SaSquared Ventures believes in what is considered “Good Business Pressure” that drives the business owner to startup and succeed. To that effect, we require the micro ventures to pay back the capital with a very small interest. This “good business pressure” not only drives them to work hard, but also gives an upcoming venture the opportunity to get funded as well” <span className='text--primary'>- Osato Osayande, COO, SaSquared</span> Ventures.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">About the GoStartupGo Foundation</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">The vision of the GoStartupGo foundation is to encourage and enable successful business ownership for the people who are struggling to make a basic living in the face of very difficult economies.</p>
                        <ul className='ps-1'>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--18 text-black fw--600 mb-2'>Small businesses are crucial to every economy.</p>
                                    <p className='fs--16 text--white3 lh-35'>The vision of the GoStartupGo foundation is to encourage and enable successful business ownership for the people who are struggling to make a basic living in the face of very difficult economies.</p>
                                </div> 
                                
                            </li>
                            <li className='list-unstyled pb-3 fs--18 d-flex align-items-start' data-aos="fade-up">
                                <img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-1' />
                                <div className='fontPoppins'>
                                    <p className='fs--18 text-black fw--600 mb-2'>Starting up a business does not automatically guarantee success.</p>
                                    <p className='fs--16 text--white3 lh-35'>At GoStartupGo, we focus primarily on activities that create entrepreneurs and set them up to start and succeed at running their businesses. To that effect, we train entrepreneurs and partner with 3rd parties to provide tools, services, and funding to equip them throughout their business journey. <span className='text-black'>Learn more at:</span> <Link to='https://gostartupgo.org/' className='text--primary link--hover text-decoration-none'>GoStartupGo.org</Link></p>
                                </div> 
                                
                            </li>
                        </ul>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={Section5} alt='About the GoStartupGo Foundation' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default Blog1