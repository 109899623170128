import React, { useState, useEffect } from "react";
import { WatchTheLatest } from "./WatchTheLatest";
import { WhatsAhead } from "./WhatsAhead";
import Carousel, { consts } from "react-elastic-carousel";
import AxiosService from "../../service/ApiService";
import { toast } from "react-toastify";
import { useLogout } from "../../hooks/useLogout";
import { NoData } from "./NoData";
// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 550, itemsToShow: 1 },
//   { width: 768, itemsToShow: 1 },
//   { width: 1200, itemsToShow:  },
// ];
function Home() {
  let [announcements, setAnnouncements] = useState([]);
  let [greetings, setGreetings] = useState("")
  let Logout = useLogout();
  const handleGetAllAnnouncements = async () => {
    try {
      let res = await AxiosService.get(
        "/content-management-tool/student/announcements"
      );
      if (res.data.statusCode === 200) {
        setAnnouncements(res.data.announcements);
      }
    } catch (error) {
      if (error.response.status === 401) Logout();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }
  };
  // const handleTime  = () => {
  //   const event = new Date();
  //   const eventTime = event.toLocaleString('en-US', { hour12: true, hour: 'numeric' });
    
  //   if (eventTime >= '12:00:00 AM' && eventTime < '12:00:00 PM') {
  //     setGreetings("Good Morning");
  //   } else if (eventTime >= '12:00:00 PM' && eventTime < '04:00:00 PM') {
  //     setGreetings("Good Afternoon");
  //   } else if (eventTime >= '04:00:00 PM' && eventTime < '12:00:00 AM') {
  //     setGreetings("Good Evening");
  //   }
  // }
  const handleTime = () => {
    const event = new Date();
    const eventTime = event.getHours();
  
    if (eventTime >= 0 && eventTime < 12) {
      setGreetings("Good Morning");
    } else if (eventTime >= 12 && eventTime < 16) {
      setGreetings("Good Afternoon");
    } else if (eventTime >= 16 && eventTime < 24) {
      setGreetings("Good Evening");
    }
  };

  useEffect(() => {
        handleGetAllAnnouncements();
        handleTime()
  }, []);

    

  return (
    <>
      <div className="studentAnnoucementContainer">
        <div className="bg-white inner">
          {/* <div className='bg-white p-4 fontPoppins'>
              <h3 className='fw--600 fs--24'>Good Morning, Leo</h3>
              <p className='fs--18 fw--500 text--grey6 mb-4'>Happening At GSGF Academy</p>
              <div className='studentAnnoucement p-4'>
                <p className='mb-5 text-white fs--16'>The SGS Foundation pilot commences July 1<sup>st</sup>. Apply now to get into the first batch!</p>
                <button className='btn--primary font-weight-normal py-1 fs--16 border-0'>Apply for GSGF Training</button>
              </div>
            </div> */}

          <div className=" p-4">
            <h3 className="fw--600 fs--24">
              {greetings}, {sessionStorage.getItem("username")}
            </h3>
            <p className="fs--18 fw--500 text--grey6 mb-0">
              Happening At GSGF Academy
            </p>
          </div>

          {
            announcements.length!==0?<Carousel itemPosition={consts.START} breakPoints={1}>
            {announcements?.map((annocement) => {
              return (
                <div className="bg-white fontPoppins w100per ">
                  <div>
                        
                          <img src={annocement?.announcementUrl} className="Dannouncementimg" alt={annocement?.announcementUrl} />

                      
                        <div className="DimageOverlay2 p-4 position-relative">
                          <p
                            className="my-4 text-white fs--16 text-center"
                            style={{ color: "black" }}
                          >
                             {annocement?.title}
                            {/* The SGS Foundation pilot commences July 1<sup>st</sup>.
                        Apply now to get into the first batch! */}
                          </p>
                          <p className='text-center m-0'><a href={annocement?.redirectUrl} target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>
                          <button className="btn--primary btn--dprimary font-weight-normal py-1 fs--16 border-0 ">
                          {annocement?.buttonText}
                            {/* Apply for GSGF Training */}
                          </button>
                          </a></p>
                        </div>
                      </div>
                </div>
              );
            })}
          </Carousel>:<NoData/>
          }
          <div className="bg-white">
            <h4 className="fs--20 fw--500 p-4 fontPoppins mb-0">
              Watch The Latest
            </h4>
            <WatchTheLatest />
          </div>
        </div>
      </div>
      <div className="whatAheadRight">
        <WhatsAhead />
      </div>
    </>
  );
}

export default Home;
