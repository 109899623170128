import React,{useState} from 'react'
import {useStripe, useElements,PaymentElement} from '@stripe/react-stripe-js';
import AxiosService from '../../service/ApiService';
import {toast} from 'react-toastify'

function StripeCheckout({amount,currency}) {
    const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const intentOptions ={amount,currency}
    const res = await AxiosService.post('/stripe/create-intent',intentOptions)
    
    const {clientSecret} = res.data

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_BASE_URL}/payment-success`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error);
    } else {
        toast.success("Your Payment is Success")
    }
  };
  return <>
    <form onSubmit={handleSubmit}>
        {/* <PaymentElement /> */}
        <button className='btn--primary py-2 border-0 mt30' aria-label='Make an Impact'
        type='submit'
        disabled
        >
            Make an Impact</button>
        {errorMessage && <div>{errorMessage}</div>}
        </form>
  </>
}

export default StripeCheckout