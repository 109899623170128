import React,{useSate,useEffect,useState} from 'react'
import Logo from '../../images/landingPage/blackLogo.svg';
import payment from '../../images/landingPage/payment-img.gif';
import { useNavigate } from 'react-router-dom';

function PaymentVerification() {
  let navigate = useNavigate()
  let [time,setTime] = useState(10)

  useEffect(()=>{
    setTimeout(()=>{
      setTime(time-1)
      if(time===0)
      { 
        navigate('/donate')
      }
    },1000)
  },[time])

  return <>
    <div className='studentBodyContainer height100vh  displayFlex alignCenter spaceBetween'>
      <div className='thankyoupage'>

        <div className=' displayFlex alignCenter spaceBetween'>
          <img src={Logo} alt='GoStartupGo' className='logo' />
        </div>
        <div className=' displayFlex alignCenter spaceBetween'>
          <img src={payment} alt='GoStartupGo' className='w300' />
        </div>
        <div className='titletext2'>Thank You for your Donation</div>
        <div className='mt10'>Your payment is successful, We will send you a receipt shortly</div>
        <div className='mt10'>You will be redirected back to the donations page</div>
        <div className='mt10'>{time}s</div>




      </div>

    </div>



  </>

}

export default PaymentVerification