import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/home/banner.jpg';
import Banner1 from '../../images/landingPage/home/banner1.jpg';
import FromEmpowermentBanner from '../../images/landingPage/home/FromEmpowermenttoIncome.jpg';
import EmpoweringEntrepreneurship from '../../images/landingPage/home/EmpoweringEntrepreneurship.svg';
import AccessResources from '../../images/landingPage/home/AccessResources.svg';
import LevelingField from '../../images/landingPage/home/LevelingField.svg';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import WhyBusiness from '../../images/landingPage/home/WhyBusiness.jpg';
import OurGoal1 from '../../images/landingPage/home/OurGoal1.jpg';
import OurGoal2 from '../../images/landingPage/home/OurGoal2.jpg';
import OurGoal3 from '../../images/landingPage/home/OurGoal3.jpg';
import Partner1 from '../../images/landingPage/home/partner1.jpg';
import Partner2 from '../../images/landingPage/home/partner2.jpg';
import Partner3 from '../../images/landingPage/home/partner3.jpg';
import JoinOurCommunity from '../../images/landingPage/home/JoinOurCommunity.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './Landing.module.scss';
import { NavHashLink } from 'react-router-hash-link';
import Video from '../../images/landingPage/home/GSGFl.mp4';
import Modal from 'react-bootstrap/Modal';


function LandingPage() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
  }

  return <>
    <Header />
    <section className={`${styles.section1} container-fluid`}>
      <div className={`container py-lg-5 py-xs-0`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Empowering Lives</span> and Communities Through The Power of Entrepreneurship</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Entrepreneurship has proven to have a positive impact on today's global challenges.<br /> Each one of us, by developing and using our strengths and skills, are agents of social change.</p>
            <div className='col-sm-12' data-aos="fade-up"><Link to="https://forms.office.com/r/gYYMZfVKAY" target="_blank" className='btn--primary me-2' role='button' aria-label='Apply Now'>Apply Now</Link> <span to="/" className='btn--secondary border-0' role='button' aria-label='Watch Video' onClick={handleShow}>Watch Video</span></div>
          </div>
          <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
            <img src={Banner} alt='Slider' className='w-100 h-auto ps-lg-5 ps-xs-0' data-aos="fade-up"/>
          </div>
        </div>
      </div>
    </section>
    <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton className={`${styles.modalHeader} border-0 pb-0`}>
        </Modal.Header>
        <Modal.Body className='text-center p-0 d-flex'>
          <video controls autoPlay width="100%" className='br--5'>
            <source src={Video} type="video/webm"/>
            <source src={Video} type="video/mp4"/>
          </video>
        </Modal.Body>
    </Modal>
    <section className={`${styles.section2} container-fluid pt-5`}>
      <div className={`container`}>
        <div className='row'>
          <div className='col-sm-12 text-black text-center'>
            <h2 className='fs--38 lh-45 mb-4 fontPoppins fw--600' data-aos="fade-right">Benefits of Go Startup Go</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-10 offset-sm-1 col-xs-12'>
            <div className='row'>
              <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                <div className={`${styles.section21Inner} position-relative px-3 pb-0 mb-2`}>
                  <div className='text-center text-white'>
                    <div className={`${styles.section2Image}`}><img src={EmpoweringEntrepreneurship} alt="Empowering Entrepreneurship" className='mb-2' /></div>
                    <h3 className='fs--20 lh-35 fontPoppins mb-3 text-white'>Empowering<br /> Entrepreneurship</h3>
                    <p className={`${styles.section2Ph} fs--13 lh-20 fontPoppins text-white text-start pb-5`}>GoStartupGo empowers entrepreneurs with top-tier training, equipping them with the knowledge and skills they need to turn their business ideas into reality.  </p>
                  </div>
                </div>
                <div className={`${styles.triangle1}`}></div>
              </div>
              <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                <div className={`${styles.section22Inner} position-relative px-3 pb-0 mb-2`}>
                  <div className='text-center  text-white'>
                    <div className={`${styles.section2Image}`}><img src={AccessResources} alt="Access to Resources" className='mb-2' width='62' height='62' /></div>
                    <h3 className='fs--20 lh-35 fontPoppins mb-3  text-white'>Access to Resources</h3>
                    <p className={`${styles.section2Ph} fs--13 lh-20 mt--54px fontPoppins text-white text-start pb-5`}>GoStartupGo partners with third-party organizations to provide entrepreneurs with the best tools, services, and funding opportunities available. </p>
                  </div>
                </div>
                <div className={`${styles.rect1}`}></div>

              </div>
              <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                <div className={`${styles.section23Inner} position-relative px-3 pb-0 mb-2`}>
                  <div className='text-center  text-white'>
                    <div className={`${styles.section2Image}`}><img src={LevelingField} alt="Leveling the Playing Field" className='mb-2' width='62' height='62' /></div>
                    <h3 className='fs--20 lh-35 fontPoppins mb-3 text-white'>Leveling the <br />Playing Field</h3>
                    <p className={`${styles.section2Ph} fs--13 lh-20 fontPoppins text-white text-start pb-5`}>GoStartupGo provides support to entrepreneurs from all backgrounds, regardless of socioeconomic status, to help create a more equitable and accessible entrepreneurial landscape.  </p>
                  </div>
                </div>
                <div className={`${styles.triangle2}`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section2Ellipse} container-fluid py-5`}></section>
    <section className={`${styles.section3} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10'>
            <img src={FromEmpowermentBanner} alt='From Empowerment Banner' className='br--20 w-100 h-auto' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--30 mb-4 fontPoppins fw--600 lh-45' data-aos="fade-up">From Empowerment to Income</h2>
            <p className='fs--18 pb-4 text--white3 fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">The GoStartupGo Foundation is 
Committed to helping individuals realise their potential, build their skills and turn them into sources of income.</p>
            <ul className='ps-1'>
              <li className='list-unstyled pb-3 fs--18 fw--500' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Quality Training</li>
              <li className='list-unstyled pb-3 fs--18 fw--500' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Certification</li>
              <li className='list-unstyled pb-3 fs--18 fw--500' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Business Fundamental </li>
              <li className='list-unstyled pb-3 fs--18 fw--500' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Advanced Specialized Skills</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section4} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-5 fontPoppins'>
            <h3 className='fs--30 lh--45 fw--600' data-aos="fade-up">Why Business?</h3>
            <p className='fs--18 lh--45 text--white3' data-aos="fade-up">Business Building is the action that fights poverty</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0'>
            <img src={WhyBusiness} alt='Why Business' className='w-100 h-auto' data-aos="fade-right" />
          </div>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">Every business represents a new opportunity and a family lifted from poverty. Go Startup Go Foundation is committed to changing the world, one business at a time.</p>
            <p className='fs--18 pb-5 text-black fontPoppins lh-35 mb-0 pe-lg-5 pe-xs-0' data-aos="fade-up">A business is more than just a means to an end, it is an avenue for personal growth and making a positive impact. By starting a business, you embrace the freedom to pursue your passions and solve real-world problems. It offers the opportunity for financial independence and the power to leave a lasting legacy. At Go Start Up Go, we believe in the transformative power of entrepreneurship. We are committed to equipping entrepreneurs and small business owners with the knowledge, skills, funding and support they need to thrive.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section5} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-12 col-xs-12 text-center pb-4 fontPoppins'>
            <div className={`bgGradient--primary py-4 br--999`}>
              <h3 className={`${styles.section5Inner} fs--44 lh-45 fw--600 text-white`} data-aos="fade-up">Go Startup Go Foundation aims at enabling as many formal SMEs as possible.</h3>
            </div>
          </div>
          <div className='col-sm-12 col-xs-12 text-center px-lg-5 px-xs-0 pb-5 fontPoppins'>
            <p className='fs--18 lh--35 text--white3 text-center px-lg-5 px-xs-0' data-aos="fade-up">Job saturation in the private sector and the shrinking public sector in Africa are driving the demand for the startup of the right kind of formal enterprises that will positively impact economic development and reduce poverty in sub-Saharan Africa.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section6} container-fluid`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12 d-flex flex-column justify-content-center'>
            <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 mb-0' data-aos="fade-up">The Foundation Focuses Around<br /> Three Main Areas.</h3>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 mb-0 ' data-aos="fade-up">Training and certification: We offer foundational and specialized certification programs that covers fundamental business concepts, market research, bookkeeping, money management, coaching for success, confidence building, growing customer base, finding capital, and starting small.</p>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35' data-aos="fade-up">Funding programs: By partnering with 3rd-party organizations, go start up go provides opportunities for members to apply and compete for funding, including the Micro Venture Funding program and the Donate program.</p>
            <p className='fs--18 pb-4 text-black fontPoppins lh-35 ' data-aos="fade-up">Specialized skill training: This is offered through partnerships with organizations that provide advanced specialized skills training across multiple categories.</p>
          </div>
          <div className='col-sm-6 col-xs-12  ps-lg-5 ps-xs-0'>
            <img src={Banner1} alt='Why Business' className='w-100 h-auto' data-aos="fade-right" />
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section7} container-fluid`}>
      <div className='container'>
        <div className='row'>
          <div className={`col-sm-12 text-center mb-4`}>
            <h4 className='fs--30 fw--600 lh-25 text-uppercase fontPoppins' data-aos="fade-up">Our Goals</h4>
          </div>
          <div className='col-sm-12 col-xs-12'>
            <div className='row gx-5'>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section7Inner}  p-3 h-100`} data-aos="fade-up">
                  <img src={OurGoal1} alt='Our Goals' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <p className='fs--15 lh-25 pb-3 text--white3 fontPoppins'>Global women entrepreneurship - To enable, lift women entrepreneurs & break the cycle of extreme need</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section7Inner} p-3  h-100`} data-aos="fade-up">
                  <img src={OurGoal2} alt='Our Goals' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <p className='fs--15 lh-25 pb-3 text--white3 fontPoppins'>Enabling formal SMES - Providing support and resources to small and medium-sized enterprises (SMEs) to help them become formalized and registered as legal entities.</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section7Inner} p-3`} data-aos="fade-up">
                  <img src={OurGoal3} alt='Our Goals' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <p className='fs--15 lh-25 pb-3 text--white3 fontPoppins'>Accelerating Africa&apos;s social and economic development - Promoting and facilitating growth, progress, and positive change in African societies, economies, and communities through entrepreneurship. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.section8} container-fluid py-5 bg--grey1`}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12 text-center mb-5'>
            <h4 className='fs--45 fw--600 lh-35 fontPoppins mb-0' data-aos="fade-up">Join Our Community</h4>
          </div>
          <div className='col-sm-12 col-xs-12 mb-4'>
            <img src={JoinOurCommunity} alt='Join Our Community' className='w-100 h-auto br--20' data-aos="fade-up" />
          </div>
          <div className='col-sm-12 text-center' data-aos="fade-up"> <NavHashLink to="/sign-up"  scroll={el => scrollWithOffset(el)} className='btn--secondary px-5' role='button' aria-label='Join Now'>Join Now</NavHashLink></div>
        </div>
      </div>
    </section>
    <section className={`${styles.section9} container-fluid bg--grey1`}>
      <div className='container'>
        <div className='row'>
          <div className={`col-sm-12 text-start mb-4`}>
            <h4 className='fs--45 fw--600 lh-35 fontPoppins' data-aos="fade-right">Partner with us</h4>
          </div>
          <div className='col-sm-12 col-xs-12'>
            <div className='row gx-5'>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section9Inner} bg-white p-3 h-100`} data-aos="fade-up">
                  <img src={Partner1} alt='Our Goals' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <h5 className='fs--22 lh-35 fw--600'>Micro Venture Funding</h5>
                    <p className='fs--16 lh-35 pb-3 text--white3 fontPoppins'>Your partnership will provide funding opportunities for certified GSG foundation ventures that have been through the readiness vetting process. Readiness includes</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section9Inner} bg-white p-3  h-100`} data-aos="fade-up">
                  <img src={Partner2} alt='Donate' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <h5 className='fs--22 lh-35 fw--600'>Donate</h5>
                    <p className='fs--16 lh-35 pb-3 text--white3 fontPoppins'>There are lots of qualified members that require some funding to help them get trained or startup their business. Join our cause to bring help and hope to them.</p>
                  </div>
                </div>
              </div>
              <div className='col-sm-4 col-xs-12 mb-5'>
                <div className={`${styles.section9Inner} bg-white p-3`} data-aos="fade-up">
                  <img src={Partner3} alt='Specialized Skill Training' className={`${styles.section6InnerImg} w-100 h-auto mb-4 br--5`} />
                  <div>
                    <h5 className='fs--22 lh-35 fw--600'>Specialized Skill Training </h5>
                    <p className='fs--16 lh-35 pb-3 text--white3 fontPoppins'>We are looking to partner with organizations that can offer our certified members advanced specialized skills training across multiple categories. If you offer paid training in one of our </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 text-center mb-5' data-aos="fade-up"> <Link to="/partner" className='btn--outline--primary px-5' role='button' aria-label='Learn More'>Learn More</Link></div>
        </div>
      </div>
    </section>
    <section className={`${styles.section10} container-fluid bgGradient--primary`}>
      <div className={`container py-5`}>
        <div className='row'>
          <div className='col-sm-6 col-xs-12'>
            <h2 className='fs--30 mb-3 fontMontserrat text-white fw--600 lh-38' data-aos="fade-up">Go Start Up Go is the ideal partner to turn your small business dreams into reality.</h2>
            <p className='fs--16 text--grey1 mb-0 fontMontserrat lh-25' data-aos="fade-up">With Go Start Up Go, you'll have the support and tools necessary to be the best version of yourself and realize your full potential.</p>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <Copyright />
  </>
}

export default LandingPage