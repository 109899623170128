import React,{useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import styles from '../../components/landingpage/Donate.module.scss';
import Stripe from '../payments/Stripe';
import Paystack from '../payments/Paystack';

export const DonateOnce = () => {
    let [amount, setAmount] = useState(100)
    let [currencySymbol, setCurrencySymbol] = useState('$')
    let [currency,setCurrency] = useState('usd')
    let currencyDetails = [
        {
            symbol:'₦',
            currency:"ngn",
            label:'Naira'
        },
        {
            symbol:`£`,
            currency:"gbp",
            label:'Pounds'
        },
        {
            symbol:`$`,
            currency:"usd",
            label:'Dollar'
        },
        {
            symbol:`€`,
            currency:"eur",
            label:'Euro'
        }
    ]
    return (
        <div>
            <InputGroup className="donateInputGroup mb-3">
                <span className={`${styles.currencyText} fs--26 fontMontserrat fw--700`}>{currencySymbol}</span>
                <Form.Control 
                aria-label="Enter amount" 
                value={amount} 
                onChange={(e)=>setAmount(Number(e.target.value))} 
                className={`${styles.currencyTextBox} fs--26 fontMontserrat fw--700`} 
                />
                <DropdownButton
                    variant="outline-secondary"
                    title={currencySymbol}
                    id="input-group-dropdown-2"
                    align="end"
                >
                    {
                        currencyDetails.map((e)=>{
                            return <>
                                <Dropdown.Item value={e.currency} 
                                    onClick={()=>{setCurrency(e.currency);setCurrencySymbol(e.symbol)}}
                                >
                                    <span className='text--blue pe-3'>{e.symbol}</span> {e.label}
                                </Dropdown.Item>
                            </>
                        })
                    }
                </DropdownButton>
            </InputGroup>
            <div className={`${styles.payment} mb-5`}>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(25)}>
                    <input type="radio" name="amount" value="25" id="a1" />
                    <label for="a1" className='radio-alias'>{currencySymbol} 25</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(50)}>
                    <input type="radio" name="amount" value="50" id="a2" />
                    <label for="a2" className='radio-alias'>{currencySymbol} 50</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(100)}>
                    <input type="radio" name="amount" value="50" id="a3" />
                    <label for="a3" className='radio-alias'>{currencySymbol} 100</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(250)}>
                    <input type="radio" name="amount" value="50" id="a4" />
                    <label for="a4" className='radio-alias'>{currencySymbol} 250</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(300)}>
                    <input type="radio" name="amount" value="50" id="a5" />
                    <label for="a5" className='radio-alias'>{currencySymbol} 300</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(500)}>
                    <input type="radio" name="amount" value="50" id="a6" />
                    <label for="a6" className='radio-alias'>{currencySymbol} 500</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(1000)}>
                    <input type="radio" name="amount" value="50" id="a7" />
                    <label for="a7" className='radio-alias'>{currencySymbol} 1,000</label>
                </div>
                <div className={`${styles.amountText}`} onClick={()=>setAmount(2500)}>
                    <input type="radio" name="amount" value="50" id="a8" />
                    <label for="a8" className='radio-alias'>{currencySymbol} 2,500</label>
                </div>
            </div>
            {
                currency !== 'ngn' ? 
                <Stripe currency={currency} amount={Number(amount)!==0?Number(amount):1}/>:
                <> <Paystack amount={Number(amount)!==0?Number(amount*100):1}/> </>
            }
        </div>
    )
}
