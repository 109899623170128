import React, { useEffect } from 'react';
import Banner from '../../images/landingPage/WhoWeAre/banner.jpg';
import Section2 from '../../images/landingPage/WhoWeAre/section2.jpg';
import Section3 from '../../images/landingPage/WhoWeAre/section3.jpg';
import Section21 from '../../images/landingPage/WhoWeAre/mission.jpg';
import EmpoweringEntrepreneurship from '../../images/landingPage/home/EmpoweringEntrepreneurship.svg';
import AccessResources from '../../images/landingPage/home/AccessResources.svg';
import LevelingField from '../../images/landingPage/home/LevelingField.svg';
import AngelOkojie from '../../images/landingPage/WhoWeAre/AngelOkojie.svg';
import GaurangSharma from '../../images/landingPage/WhoWeAre/GaurangSharma.svg';
import NaziaSiddiqui from '../../images/landingPage/WhoWeAre/NaziaSiddiqui.svg';
import LeonardNdubuisi from '../../images/landingPage/WhoWeAre/LeonardNdubuisi.svg';
import SallyOdianah from '../../images/landingPage/WhoWeAre/SallyOdianah.svg';
import MercyChibuikeIheama from '../../images/landingPage/WhoWeAre/MercyChibuike-Iheama.svg';
import IroOmere from '../../images/landingPage/WhoWeAre/IroOmere.svg';
import ParryOsayande from '../../images/landingPage/WhoWeAre/ParryOsayande.png';
import IreneOsayande from '../../images/landingPage/WhoWeAre/IreneOsayande.png';
import Osatoayande from '../../images/landingPage/WhoWeAre/Osatoayande.png';
import SayuAbend from '../../images/landingPage/WhoWeAre/sayu.png';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './WhoWeAre.module.scss';
import { NavHashLink } from 'react-router-hash-link';

function WhoWeAre() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>Our</span> vision is simple…</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">To encourage and enable successful business ownership for the people who are struggling to make a basic living in the face of difficult economies.</p>
                        <div className='col-sm-12' data-aos="fade-up"><NavHashLink to="/get-involved"  scroll={el => scrollWithOffset(el)}className='btn--primary me-2' role='button' aria-label='Get Involved'>Get Involved</NavHashLink> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
                        <img src={Banner} alt='Slider' data-aos="fade-up" className='w-100 h-auto ps-lg-5 ps-xs-0'/>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 order-lg-1 order-xs-2'>
                        <img src={Section2} alt='Why Business' className='w-100 h-auto br--10 m-mb-10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Small businesses are crucial to every economy.</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">They account for majority of the total number of businesses in most countries, driving new jobs and a significant amount of economic activity. They are basically considered critical to the GDP of any economy.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Starting up a business does not automatically guarantee success.</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">At GoStartupGo, we focus primarily on activities that create entrepreneurs and set them up to start and succeed at running their businesses. To that effect, we train entrepreneurs and partner with 3rd parties to provide tools, services, and funding to equip them throughout their business journey.</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0'>
                        <img src={Section3} alt='Why Business' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 order-lg-1 order-xs-2'>
                        <img src={Section21} alt='Why Business' className='w-100 h-auto br--10 m-mb-10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">OUR MISSION STATEMENT:</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">At GoStartUpGo, we have a clear mission: to help entrepreneurs and business owners succeed. We innovate around small businesses by providing practical programs, personal mentorship, technology, resources and a supportive community. With our different projects and programs, our goal is to create an environment where entrepreneurs and businesses can grow, collaborate, and make a lasting impact.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section4} container-fluid py-5`}>
            <div className={`container`}>
                <div className='row'>
                    <div className='col-sm-10 offset-sm-1 col-xs-12'>
                        <div className='row'>
                            <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                                <div className={`${styles.section41Inner} position-relative px-3 pb-0 m-mb-10`}>
                                    <div className='text-center text-white'>
                                        <div className={`${styles.section4Image}`}><img src={EmpoweringEntrepreneurship} alt="Partner with us" className='mb-2' /></div>
                                        <h3 className='fs--20 lh-35 fontPoppins mb-3 text-white'>Partner with <br />us</h3>
                                        <p className={`${styles.section4Ph} fs--13 lh-20 fontPoppins text-white text-start pb-5`}>Join our network of impact investors and help fund the next generation of African entrepreneurs.</p>
                                    </div>
                                </div>
                                <div className={`${styles.triangle1}`}></div>
                            </div>
                            <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                                <div className={`${styles.section42Inner} position-relative px-3 m-mb-10`}>
                                    <div className='text-center  text-white'>
                                        <div className={`${styles.section4Image}`}><img src={AccessResources} alt="Our impact" className='mb-2' width='62' height='62' /></div>
                                        <h3 className='fs--20 lh-35 fontPoppins mb-3  text-white'>Our impact</h3>
                                        <p className={`${styles.section4Ph} fs--13 lh-20 mt--54px fontPoppins text-white text-start pb-5`}>At Go Start Up Go, we believe that entrepreneurship can change lives. Hear from some of our success stories and learn how our programs and initiatives are making a difference</p>
                                    </div>
                                </div>
                                <div className={`${styles.rect1}`}></div>
                            </div>
                            <div className='col-sm-4 col-xs-12' data-aos="fade-up">
                                <div className={`${styles.section43Inner} position-relative px-3 pb-0 m-mb-10`}>
                                    <div className='text-center  text-white'>
                                        <div className={`${styles.section4Image}`}><img src={LevelingField} alt="Leveling the Playing Field" className='mb-2' width='62' height='62' /></div>
                                        <h3 className='fs--20 lh-35 fontPoppins mb-3 text-white'>Our programs and <br />initiatives</h3>
                                        <p className={`${styles.section4Ph} fs--13 lh-20 fontPoppins text-white text-start pb-5`}>If you are looking to grow your existing business or start a new one, our accelerator program offers top-notch training and mentorship to help take you to the next level.</p>
                                    </div>
                                    <div className={`${styles.triangle2}`}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section4Ellipse} container-fluid py-5 mb-5`}></section>

        <section className={`${styles.section5} container-fluid py-5 bg--grey1`}>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 text-center mb-5'>
                        <h4 className='fs--45 fw--600 lh-35 fontPoppins mb-0' data-aos="fade-up">Leadership Team</h4>
                    </div>
                    <div className='col-sm-8 offset-sm-2 col-xs-12 mb-5'>
                        <div className='row'>
                            <div className='col-sm-4 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={AngelOkojie} alt='Angel Okojie' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Angel Okojie</h5>
                                    <p className='fs--16 lh-35 text-uppercase text-black'>DIRECTOR</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12 fontPoppins'>
                                <div className='text-center'>
                                    <img src={GaurangSharma} alt='Gaurang Sharma' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Gaurang Sharma</h5>
                                    <p className='fs--16 lh-35 text-uppercase'>HEAD OF DEVELOPMENT</p>
                                </div>
                            </div>
                            <div className='col-sm-4 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={NaziaSiddiqui} alt='Nazia Siddiqui' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Nazia Siddiqui</h5>
                                    <p className='fs--16 lh-35 text-uppercase'>Head of Products & SecOps</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-10 offset-sm-1 col-xs-12 mb-4'>
                        <div className='row'>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={LeonardNdubuisi} alt='Leonard Ndubuisi' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Leonard Ndubuisi</h5>
                                    <p className='fs--16 lh-35 text-uppercase text-black'>Head of Project Management</p>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12 fontPoppins'>
                                <div className='text-center'>
                                    <img src={SallyOdianah} alt='Sally Odianah' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Sally Odianah</h5>
                                    <p className='fs--16 lh-35 text-uppercase'>Head of Partnerships</p>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={MercyChibuikeIheama} alt='Mercy Chibuike-Iheama' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Mercy Chibuike-Iheama</h5>
                                    <p className='fs--16 lh-35 text-uppercase'>Head of Finance</p>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={IroOmere} alt='Iro Omere' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Iro Omere</h5>
                                    <p className='fs--16 lh-35 text-uppercase'>Dean, GSG Foundation School</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className={`${styles.section6} container-fluid py-5 bg--grey1`}>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 text-center mb-5'>
                        <h4 className='fs--45 fw--600 lh-35 fontPoppins mb-0' data-aos="fade-up">Board Of Trustees</h4>
                    </div>
                    <div className='col-sm-10 offset-sm-1 col-xs-12 mb-4'>
                        <div className='row'>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={ParryOsayande} alt='Parry Osayande' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Parry Osayande</h5>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12 fontPoppins'>
                                <div className='text-center'>
                                    <img src={IreneOsayande} alt='Irene Osayande' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Irene Osayande</h5>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={Osatoayande} alt='Osato Osayande' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Osato Osayande</h5>
                                </div>
                            </div>
                            <div className='col-sm-3 col-xs-12'>
                                <div className='text-center fontPoppins'>
                                    <img src={SayuAbend} alt='Sayu Abend' className='mb-4' />
                                    <h5 className='text--primary fs--24 lh-45'>Sayu Abend</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default WhoWeAre