import React from 'react';
import { Link, NavLink , useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../../../images/landingPage/blackLogo.svg';

const Header = () => {
  let navigate = useNavigate()
  return (
      <Navbar expand="lg" fixed="top" className='navbar--container py-3 bg-white shadow-sm'>
        <Container className='header--container'>
          <Navbar.Brand className='me-md-auto p-0'>
            <Link to='/'><img src={Logo} alt='GoStartupGo' className='logo'/></Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div className='d-flex w-100 m-menu'>
            <Navbar.Collapse id="navbarScroll" className='justify-content-center'>
              <Nav
                className="my-2 my-lg-0"
                navbarScroll
              >
                <NavLink  to="/who-we-are" activeClassName="active" className={"me-1 nav-NavLink "}>Who we are</NavLink>
                <NavLink  to="/what-we-do" activeClassName="active" className={"me-1 nav-link"}>What we do</NavLink >
                <NavLink  to="/our-impact" activeClassName="active" className={"me-1 nav-link"}>Our Impact</NavLink >
                <NavLink  to="/news" activeClassName="active" className={"me-1 nav-link"}>News & Events</NavLink >
                <NavLink  to="/partner" activeClassName="active" className={"me-1 nav-link"}>Partner with us</NavLink >
                <NavLink  to="/get-involved" activeClassName="active" className={"me-1 nav-link"}>Get Involved</NavLink >
              </Nav>
            </Navbar.Collapse>
            <div className='d-flex button--container'>
              <button onClick={() => navigate('/sign-up')} className='btn--outline--signup border-0 me-2' role='button' aria-label='Sign Up'>Sign Up</button>
              <button onClick={() => navigate('/sign-in')} className='btn--outline--signin border-0' role='button' aria-label='Sign In'>Sign In</button>
            </div>
          </div>
        </Container>
      </Navbar>
  )
}

export default Header;