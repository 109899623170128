import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import previewIcon from "../../../images/preview.png";
import editorIcon from "../../../images/editor.png";
import saveIcon from "../../../images/save.png";
import AnnouncementImg from "../../../images/annocement.png";
import { useFormik } from "formik";
import AxiosService from "../../../service/ApiService";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { url } from "../../utils/Regex";
import deleteIcon from "../../../images/delete.png";

function AddAnnouncementSection() {
  const navigate = useNavigate()
  const [inputKey, setInputKey] = useState(Date.now());
  const [imageFile, setImageFile] = useState();
  const [previewImg, setpreviewImg] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [reupload, setreupload] = useState(false);
  const [title, setTitle] = useState(
    "Title"

  )
  const [buttonText, setbuttonText] = useState(
    "Button Text"

  )

  const params = useParams()
  const [announcement, setAnnouncement] = useState({})
  const handleAnnouncementById = async () => {
    try {
      let { data } = await AxiosService.get(`/content-management-tool/announcement/${params?.id}`)

      if (data.statusCode === 200) {
        // setEvent(data.event[0])
        setAnnouncement(data.announcement[0])
        // setIsFilePicked(true)



        // setpreviewImg(URL.createObjectURL(data.announcement[0].file));
      }

    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }




  const getFileExtension = (filename) => {
    return filename?.slice(filename.lastIndexOf('.'));
  };


  const changeHandler = (event) => {

    const file = event.target.files[0]; // Assuming you are uploading a single file
    const maxSizeInBytes = 500 * 1024; // 500 KB
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];

    if (file?.size <= maxSizeInBytes && allowedExtensions.includes(getFileExtension(file?.name).toLowerCase())) {
      setImageFile(event.target.files[0]);
      setpreviewImg(URL.createObjectURL(event.target.files[0]));
      if (params?.id) {
        setreupload(true)
      }

      event.target.files[0] && setIsFilePicked(true);
    } else if (file?.size > maxSizeInBytes) {
      toast.error('The file size is greater than 500 KB.')
    } else if (!allowedExtensions.includes(getFileExtension(file?.name).toLowerCase())) {
      toast.error('Invalid file type. Only JPG and PNG files are allowed.')
    } else {
      toast.error('File size and type are valid.')
    }
    setInputKey(new Date())

  };
  const imageFormik = useFormik({
    initialValues: {
      title: announcement !== "" ? announcement.title : "Title",
      buttonText: announcement !== "" ? announcement.buttonText : "Button Text",
      redirectUrl: announcement !== "" ? announcement.redirectUrl : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("required"),
      buttonText: Yup.string().required("required"),
      redirectUrl: Yup.string().required("required").matches(url, "Invalid Url"),
    }),
    onSubmit: async (values) => {
      if (imageFile) {
        const formData = new FormData();
        formData.append("file", imageFile);
        formData.append("title", values.title);
        formData.append("buttonText", values.buttonText);
        formData.append("redirectUrl", values.redirectUrl);
        // handleAddAnnouncement(formData)
        params.id ? handleEditAnnouncementById(formData) :
          handleAddAnnouncement(formData);

      } else {
        if( params.id && !imageFile) {
           handleEditAnnouncementById(values)
        }else if(!imageFile){
          toast.error('Please select an image')
        }
      }
    },

  });
  const handleAddAnnouncement = async (formData) => {
    try {
      let { data } = await AxiosService.post("/content-management-tool/announcement/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.statusCode === 200) {
        navigate('/admin/announcement-section')
        // handleClose()
        toast.success(data.message)
      }
    } catch (error) {
      // console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );
    }

  }

  useEffect(() => {

    if (params.id)
      handleAnnouncementById()
  }, [])



  const handleEditAnnouncementById = async (formData) => {
    try {
      let { data } = await AxiosService.put(`/content-management-tool/announcement/edit/${params?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data.statusCode === 200) {
        navigate('/admin/announcement-section')
        toast.success(data?.message)
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : error.response.data.message[0]
      );

    }
  }
  const Editor = () => {
    return (
      <div>
        <Form className="row adminForm">
          <div className="col-sm-6 col-xs-12">
            <Form.Group className="mb-3" controlId="Title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={imageFormik.values.title}
                onChange={(e) => {
                  setTitle(e.target.value)
                  imageFormik.setFieldValue('title', e.target.value)
                }}
                onBlur={imageFormik.handleBlur}
              />
            </Form.Group>
            {imageFormik.errors.title && imageFormik.touched.title ? (
              <p className="error_text">{imageFormik.errors.title}</p>
            ) : null}
          </div>
          <div className="col-sm-6 col-xs-12">
            <Form.Group className="mb-3" controlId="ButtonText">
              <Form.Label>Button Text</Form.Label>
              <Form.Control
                type="text"
                name="buttonText"
                value={imageFormik.values.buttonText}
                onChange={(e) => {
                  setbuttonText(e.target.value)
                  imageFormik.setFieldValue('buttonText', e.target.value)
                }}
                onBlur={imageFormik.handleBlur}
              />
            </Form.Group>
            {imageFormik.errors.buttonText && imageFormik.touched.buttonText ? (
              <p className="error_text">{imageFormik.errors.buttonText}</p>
            ) : null}
          </div>
          <div className="col-sm-6 col-xs-12">
            <Form.Group className="mb-3" controlId="URL">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name="redirectUrl"
                value={imageFormik.values.redirectUrl}
                onChange={imageFormik.handleChange}
                onBlur={imageFormik.handleBlur}
              />
            </Form.Group>
            {imageFormik.errors.redirectUrl && imageFormik.touched.redirectUrl ? (
              <p className="error_text">{imageFormik.errors.redirectUrl}</p>
            ) : null}
          </div>
          <div className="col-sm-6 col-xs-12">
            <p class="form-label" for="Upload Image">
              Upload Image
            </p>

            <input
              type="file"
              key={inputKey}
              id="upload"
              accept="*/image"
              onChange={changeHandler}
              hidden
            />
            <label
              for="upload"
              className="fileUpload"
              style={{ margin: "0px" }}
            >
              {" "}
              Choose file
            </label>
            <small className="fs--12 text--grey6">
              *Image must be in png,jpeg,jpg format.<br />
              Less than 500kb
            </small>
            {imageFile ? (
              <div>
                {imageFile.name} {" "}
                <img src={deleteIcon} alt="delete" onClick={() => {
                  setImageFile(null)
                  setInputKey(new Date())
                  setIsFilePicked(false)
                }} />
              </div>
            ) : null}
          </div>
        </Form>
      </div>
    );
  };




  return (
    <>
      <div className="adminContentSection">
        <div className="titleSection">
          <div className="titleText "> Announcement section</div>
          <div className="flexSection">
            <div className="displayFlex alignCenter spaceBetween gap15">
              <button
                className="btnDark d-flex align-items-center"
                type="submit"
                onClick={() => {
                  // navigate('/admin/announcement-section')
                  imageFormik.handleSubmit();
                }}
              >
                <img src={saveIcon} alt="plusicon" className="pe-1" /> Save
              </button>
            </div>
          </div>
        </div>

        <div className="mt30">
          <div className="row">
            <div className="col-sm-12 col-xs-12">
              <div className="cmsALeft mb-4">
                <div className="header p-3">
                  <img src={editorIcon} alt="Preview icon" className="pe-1" />{" "}
                  <span className="fs--14 text--primary">Editor</span>
                </div>
                <div className="body p-3 pb-5">{Editor()}</div>
              </div>
            </div>
            <div className="col-sm-12 col-xs-12">
              <div className="cmsARight">
                <div className="header p-3">
                  <img src={previewIcon} alt="Preview icon" className="pe-1" />{" "}
                  <span className="fs--14">Preview</span>
                </div>
                <div className="body p-3 d-flex justify-content-center">
                  <div className="p-lg-5 p-xs-0">




                    {isFilePicked ? ( <>
                                         <div>
                                         <img src={previewImg} className="announcementimg" alt="preview"/>
               
                                       </div>
                        <div className="imageOverlay2 p-4">

                          <p
                            className="mb-5 text-white fs--16"
                            style={{ color: "black" }}
                          >
                            {imageFormik.values.title}
                            {/* The SGS Foundation pilot commences July 1<sup>st</sup>.
                        Apply now to get into the first batch! */}
                          </p>
                          <button className="btn--primary font-weight-normal py-1 fs--16 border-0">
                            {imageFormik.values.buttonText}
                            {/* Apply for GSGF Training */}
                          </button>


                        </div>
                        </>





                      
                    ) : (params?.id && !reupload) ?
                      <div>
                        <div>
                          <img src={announcement?.announcementUrl} className="announcementimg" alt={announcement?.announcementUrl} />

                        </div>
                        <div className="imageOverlay2 p-4">
                          <p
                            className="mb-5 text-white fs--16"
                            style={{ color: "black" }}
                          >
                            {imageFormik.values.title}
                            {/* The SGS Foundation pilot commences July 1<sup>st</sup>.
                        Apply now to get into the first batch! */}
                          </p>
                          <button className="btn--primary font-weight-normal py-1 fs--16 border-0">
                            {imageFormik.values.buttonText}
                            {/* Apply for GSGF Training */}
                          </button>

                        </div>
                      </div>



                      : (
                        <div
                          className="imageOverlayContainer br--5 bgColor"
                          style={{
                            // background: url("starsolid.gif")
                            background: `url(${AnnouncementImg} )`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            minWidth: "600px",
                            backgroundPosition: "center"
                          }}
                        >

                          <div className="imageOverlay p-4">

                            <p className="mb-5 text-white fs--16">
                              {/* {imageFormik.values.title} */}
                              {title}

                              {/* The SGS Foundation pilot commences July 1<sup>st</sup>.
                                          Apply now to get into the first batch! */}
                            </p>
                            <button className="btn--primary font-weight-normal py-1 fs--16 border-0">
                              {/* {imageFormik.values.buttonText }  */}
                              {buttonText}
                              {/* Apply for GSGF Training */}
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAnnouncementSection;
