import React from 'react';
import { Link } from 'react-router-dom';
import FacebookIcon from '../../../images/landingPage/facebook.svg';
import TwitterIcon from '../../../images/landingPage/twitter.svg';
import InstagramIcon from '../../../images/landingPage/instagram.svg';

const Copyright = () => {
  return (
    <section className='copyright--container'>
      <div className='container py-3 border--top'>
        <div className='row'>
          <div className='col-sm-4 col-xs-12'>
            <div className='d-flex justify-content-lg-start h-100 align-items-center'>
              <p className='fs--14 mb-0 fontPoppins'><Link to="/terms-of-use" className='text-decoration-none  text--white3 link--hover'> Terms of Use</Link></p>
              <span className='mx-3 text--white3 pb-1'>|</span>
              <p className='fs--14 mb-0 me-4 fontPoppins'><Link to="/privacy-policy" className='text-decoration-none text--white3 link--hover'>Privacy Policy</Link></p>
            </div>
          </div>
          <div className='col-sm-4 col-xs-12 text-center'>
            <p className='fs--14 mb-lg-0 m-mb-10 text--white3 d-flex justify-content-lg-center justify-content-xs-start  h-100 align-items-center fontPoppins'>&copy; Go-startup Go 2023. All rights reserved</p>
          </div>
          <div className='col-sm-4 col-xs-12'>
            <ul className='social--icon d-flex justify-content-lg-end  justify-content-xs-start ps-0 mb-0'>
              <li className='list-unstyled me-3'><Link to="https://www.facebook.com/GoStartupGo" target={'_blank'}><img src={FacebookIcon} alt="Facebook"></img></Link></li>
              <li className='list-unstyled me-3'><Link to="https://twitter.com/gostartupgo" target={'_blank'}><img src={TwitterIcon} alt="Twitter"></img></Link></li>
              <li className='list-unstyled me-3'><Link to="https://www.instagram.com/gostartupgo/" target={'_blank'}><img src={InstagramIcon} alt="Instagram"></img></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Copyright;