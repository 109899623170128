import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/WhatWeDo/banner.jpg';
import OurApproach from '../../images/landingPage/WhatWeDo/OurApproach.jpg';
import StrategicPartners from '../../images/landingPage/WhatWeDo/StrategicPartners.jpg';
import SpecializedCertification from '../../images/landingPage/WhatWeDo/SpecializedCertification.jpg';
import Section41 from '../../images/landingPage/WhatWeDo/section41.jpg';
import Section42 from '../../images/landingPage/WhatWeDo/section42.jpg';
import Section43 from '../../images/landingPage/WhatWeDo/section43.jpg';
import FundingPrograms from '../../images/landingPage/WhatWeDo/FundingPrograms.jpg';
import CircleCheckIcon from '../../images/landingPage/CircleCheck.svg';
import ITEPModel from '../../images/landingPage/WhatWeDo/itep.jpeg';
import Modal from 'react-bootstrap/Modal';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import HubspotForm from 'react-hubspot-form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './WhatWeDo.module.scss';


function WhatWeDo() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>What</span> We Do</h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">Our approach is centered on ensuring that the businesses we enable and empower are truly setup for success. It is not just about starting up. It is about understanding how to do business and being equipped with all the required tools to succeed.</p>
                        <div className='col-sm-12' data-aos="fade-up"><Link to="/get-involved" className='btn--primary me-2' role='button' aria-label='Make an Impact'>Make an Impact</Link> </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
                        <img src={Banner} alt='Slider' data-aos="fade-up" className='w-100 h-auto ps-lg-5 ps-xs-0' />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={OurApproach} alt='Our Approach' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Our Approach</h3>
                        <p className='fs--18 pb-1 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">We teach concepts like like deciding what business to go into, assessing competition, how to reach your addressable market, understanding business startup and operating capital, etc.</p>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">We follow our very successful <button className='cursor-pointer text--primary text-decoration-none link--hover border-0 bg-transparent p-0' onClick={handleShow}>ITEP Model.</button> </p>
                    </div>
                    <Modal show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton className={`${styles.modalHeader} border-0 pb-0`}>
                        </Modal.Header>
                        <Modal.Body className='text-center p-0'>
                            <img src={ITEPModel} alt='ITEP Model' className='w-100 h-auto br--10' />
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12 text-center'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Foundational Certification </h3>
                        <p className='fs--18 pb-1 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">This is a must-have for any prospect wanting to become a GSG member. It will also be a required criteria for those that want to apply for funding. Certified graduates will also be certified by our partner organization Street Business School. They will learn fundamental business concepts including:</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section4} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className={`${styles.section4Inner} col-sm-4 col-xs-12 p-4`}>
                        <img src={Section41} alt='Market Research' className='br--5 mb-3 w-100' data-aos="fade-up" />
                        <ul className='ps-3'>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Market Research</li>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Business Planning</li>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Bookkeeping </li>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Money Management</li>
                        </ul>
                    </div>
                    <div className={`${styles.section4Inner} col-sm-4 col-xs-12 p-4`}>
                        <img src={Section42} alt='Market Research' className='br--5 mb-3 w-100' data-aos="fade-up" />
                        <ul className='ps-3'>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Coaching for Success</li>
                            <li className='list-unstyled pb-3 fs--22 fw--600' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-2' /> Business Identification</li>
                        </ul>
                    </div>
                    <div className={`${styles.section4Inner} col-sm-4 col-xs-12 p-4`}>
                        <img src={Section43} alt='Market Research' className='br--5 mb-3 w-100' data-aos="fade-up" />
                        <ul className='ps-3'>
                            <li className='list-unstyled pb-3 fs--22 fw--600 d-flex align-items-start' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-2' /> Growing Your Customer Base</li>
                            <li className='list-unstyled pb-3 fs--22 fw--600 d-flex align-items-start' data-aos="fade-up"><img src={CircleCheckIcon} alt='Circle Check' className='me-3 pt-2' /> Finding Capital and Starting Small</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section5} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Funding Programs</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">In partnerships with 3rd-party organizations, GSG will periodically launch funding programs that will give members the opportunity to apply and compete for funding. Some of these programs will be launched internally by the GSG foundation.</p>
                        <div className={`${styles.section5Inner} position-relative p-4 m-mb-40`}>
                            <p className='fs--18 lh-35 fontPoppins fst-italic text--white3 mb-2' data-aos="fade-up">Partnerships like these are key to our ability to scale and succeed which is fundamental to our approach in helping business owners” said </p>
                            <p className='fs--18 text-end fontPoppins fst-italic fw--600 mb-4 pe-lg-5 pe-xs-0' data-aos="fade-up">-- Joe Tokkar</p>
                        </div>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={FundingPrograms} alt='Funding Programs' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section6} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-12 col-xs-12' >
                        <div className={`${styles.section6Inner} position-relative p-5 text-center bgGradient--primary br--999`}>
                            <p className='fs--44 lh-55 fw--600 fontPoppins text-white mb-2' data-aos="fade-up">We believe in making friends, not contacts. We believe in giving first, not taking. We believe in helping others before helping yourself. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section7} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <img src={StrategicPartners} alt='Strategic Partners' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-5 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Strategic Partners</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">Our strategic partnerships are critical to delivery on the overall vision. To that effect we have and will continue to grow our strategic partnerships</p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section8} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-5 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Specialized Certification</h3>
                        <p className='fs--18 pb-4 text-black fontPoppins lh-35 pe-lg-5 pe-xs-0' data-aos="fade-up">“ Some certified members will choose to do specialized training in the area of their chosen business venture. We have partnered to enable them get this training. Members who complete this training will also graduate with a GSG Specialized Certification. </p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <img src={SpecializedCertification} alt='Specialized Certification' className='w-100 h-auto br--10' data-aos="fade-right" />
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section9} container-fluid bg--grey1 py-5`}>
            <div className={`container py-5 text-start br--20`}>
                <div className={`${styles.section9Inner} shadow-sm col-sm-12 col-xs-12 bg-white p-5 br--20`}>
                    <h3 className='fs--30 fw--600 pb-1 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">Find out More </h3>
                    <p className='fs--18 text--white3' data-aos="fade-up">Fill in the form and we will send you a brochure</p>
                    <div data-aos="fade-up">
                        <div className={`${styles.findOutMoreRight} pt-4 h-100`}>
                            <HubspotForm
                                portalId='23639501'
                                formId='07bd4aff-5810-45d7-b4c5-c001794afad8'
                                onSubmit={() => console.log('Submit!')}
                                onReady={(form) => console.log('Form ready!')}
                                loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
        <Copyright />
    </>
}

export default WhatWeDo