import React, { useState } from "react";
import Select, { components } from "react-select";
import {toast} from 'react-toastify'

// Multi Select input options and config
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#fcf1e5";
  if (isActive) bg = "#fcf1e5";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" defaultChecked={isSelected} />
      {children}
    </components.Option>
  );
};

function PaginatedTable({
  column = [],
  row = [],
  totalCount = 0,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  searchKey,
  setSearchKey,
  isExport = false,
  isFilterHeader=false,
  filterOptions=[],
  filterDefaultValue=[],
  handleFilterSelection=()=>null
}) {

  //pagintation related function starts here
  const [pageNumberLimit] = useState(3);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const [timer, setTimer] = useState(null);
  let pages = [];

  //create pages based on total count
  for (let i = 1; i <= Math.ceil(totalCount / itemsPerPage); i++) {
    pages.push(i);
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNext = () => {
    setCurrentPage((prev) => prev + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageDecrementBtn = null;
  if (minPageNumberLimit !== 0) {
    pageDecrementBtn = <li onClick={handlePrev}>&hellip;</li>;
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNext}>&hellip;</li>;
  }

  let start = (currentPage - 1) * itemsPerPage + 1;
  let end =
    totalCount < currentPage * itemsPerPage
      ? totalCount
      : currentPage * itemsPerPage;

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return <></>;
    }
  });
  //pagination related function ends here

  //handle search with delayed trigger - mannual debounce
  const handleSearch = (searchValue) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      setSearchKey(searchValue);
      setCurrentPage(1);
      setMaxPageNumberLimit(pageNumberLimit);
      setMinPageNumberLimit(0);
    }, 1000);

    setTimer(newTimer);
  };

  return (
    <>
      <div className="displayFlex alignCenter spaceBetween mt30">
        {/* Select entries per page */}
        <div>

          Show &nbsp;
          <select
            defaultValue={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
              setMaxPageNumberLimit(pageNumberLimit);
              setMinPageNumberLimit(0);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          &nbsp; Entries
        </div>
        {/* Search */}
        <div className="flexSection">
          <div className="displayFlex alignCenter spaceBetween gap15">
            <div className="search">
              <i className="fa fa-search text--primary"></i>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </div>
            <div>
              {/* Multi Select */}
              {isFilterHeader?<Select 
                className="w220px"
                defaultValue={filterDefaultValue}
                isMulti
                placeholder={"Select Column"}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                onChange={(options) => {
                    if(options.length===0)
                    {
                      toast.error("Select Atleast One Column")
                    }

                    handleFilterSelection(options)
                }}
                options={filterOptions}
                components={{
                  Option: InputOption,
                }}
              />:<></>}
            </div>
            {/* Export Button */}
            {isExport ? <button>Export</button> : <></>}
          </div>
        </div>
      </div>
      {/* Table Section - Auto Genration of Rows and columns */}
      <div className="maintable mt30">
        <div className="displayFlex">
          <table>
            <thead>
              <tr>
                {column.map((e, i) => {
                  return (
                    <th
                      style={{ minWidth: `${e.width}px` }}
                      hidden={e?.hidden}
                      key={i}
                    >
                      {e?.heading()}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {row?.map((rowData, i) => {
                return (
                  <tr key={i}>
                    {column?.map((columnData, j) => {
                      return (
                        <td
                          style={{ minWidth: `${columnData?.width}px` }}
                          key={j}
                          hidden={columnData?.hidden}
                        >
                          {columnData?.cell(rowData,start+i)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* Pagination Section */}
      <div className="displayFlex alignCenter spaceBetween mt30">
        <div>
          Showing {start} to {end} of {totalCount} entries
        </div>
        <ul className="pageNumbers">
          <li>
            <button onClick={handlePrev} disabled={currentPage === 1}>
              Prev
            </button>
          </li>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}
          <li>
            <button
              onClick={handleNext}
              disabled={currentPage === pages.length}
            >
              Next
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default PaginatedTable;
