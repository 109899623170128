import React, {useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../images/landingPage/news/banner.jpg';
import SaSquared from '../../images/landingPage/news/SaSquared.jpg';
import Section2 from '../../images/landingPage/news/section2.jpg';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import AOS from 'aos';
import 'aos/dist/aos.css';
import styles from './News.module.scss';

function News() {
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return <>
        <Header />
        <section className={`${styles.section1} container-fluid`}>
            <div className={`container py-lg-5 py-xs-0`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12'>
                        <h2 className='fs--36 mb-4 pt-lg-5 pt-xs-0 fontPoppins fw--600 lh-55' data-aos="fade-up"><span className='text--primary'>GoStartupGo partners with Organizations to </span>  create a significant impact and transform lives of Individuals </h2>
                        <p className='fs--18 pb-4 text--white3 fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up">"Partnerships like these are key to our ability to scale and succeed which is fundamental to our approach in helping business owners"</p>
                    </div>
                    <div className='col-sm-6 col-xs-12 py-3 d-flex justify-content-end'>
                        <img src={Banner} alt='Slider' data-aos="fade-up" className='w-100 h-auto ps-lg-5 ps-xs-0'/>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section2} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                    <div className='col-sm-6 col-xs-12 pe-lg-5 pe-xs-0 m-mb-10 order-lg-1 order-xs-2'>
                        <div className='position-relative'>
                            <img src={SaSquared} alt='GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>January 3rd, 2023</div>
                        </div>
                       
                    </div>
                    <div className='col-sm-6 col-xs-12 order-lg-2 order-xs-1'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 ps-lg-5 ps-xs-0' data-aos="fade-up">GoStartupGo partners with SaSquared Ventures to fund micro entrepreneurs</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  ps-lg-5 ps-xs-0' data-aos="fade-up">“SaSquared Ventures believes in what is considered “Good Business Pressure” that drives the business owner to startup and succeed. To that effect, we require the micro ventures to pay back the capital with a very small interest. This “good business pressure” not only drives them to work hard, but also gives an upcoming venture the opportunity to get funded as well” <span className='text--primary'>- Osato Osayande, COO, SaSquared</span> Ventures. <Link to='/news/blog1' className='text-underline text--grey2 fs--18 link--hover'>Read more</Link></p>
                    </div>
                </div>
            </div>
        </section>
        <section className={`${styles.section3} container-fluid`}>
            <div className={`container py-5`}>
                <div className='row'>
                   <div className='col-sm-6 col-xs-12'>
                        <h3 className='fs--30 fw--600 pb-4 text-black fontPoppins lh-45 pe-lg-5 pe-xs-0' data-aos="fade-up">GoStartupGo partners with SpacePointe to provide business management EkiKart</h3>
                        <p className='fs--18 pb-1 text-black fontPoppins lh-35  pe-lg-5 pe-xs-0' data-aos="fade-up"><span className='text--primary'>Edo State Nigeria</span><br/> The GoStarupGo (GSG) foundation, a leading business enablement non-profit organization has entered into a strategic partnership with SpacePointe Inc, a global financial technology firm. This partnership will provide business management and point of sales tools for the GoStartupGo foundation members. <Link to='/news/blog2' className='text-underline text--grey2 fs--18 link--hover'>Read more</Link></p>
                    </div>
                    <div className='col-sm-6 col-xs-12 ps-lg-5 ps-xs-0'>
                        <div className='position-relative'>
                            <img src={Section2} alt='GoStartupGo partners with SpacePointe to provide business management EkiKart' className='w-100 h-auto br--10' data-aos="fade-right" />
                            <div className={`${styles.date} bg--primary position-absolute p-2  br--10 text-white`}>Feb 13th, 2023</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 
        <Footer />
        <Copyright />
    </>
}

export default News