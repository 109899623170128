import React, { useState, useEffect } from "react";
import AddBusinessOwner from "./AddBusinessOwner";
import BusinessOwner from "./BusinessOwner";
import plusIcon from "../../images/plus-icon.svg";
import actionIcon from "../../images/action-icon.svg";
import AxiosService from "../../service/ApiService";
import PaginatedTable from "../common/PaginatedTable";
import { toast } from 'react-toastify'

function BusinessOwnerManagement() {
  let [addToggle, setAddToggle] = useState(false);
  let [viewToggle, setViewToggle] = useState(false);
  let [totalCount, setTotalCount] = useState(0)
  let [currentPage, setCurrentPage] = useState(1)
  let [itemsPerPage, setItemsPerPage] = useState(10)
  let [searchKey, setSearchKey] = useState(null)
  let [users, setUsers] = useState([]);
  let [userid, setuserid] = useState("")
  let [statusFilter, setStatusFilter] = useState("All");
  let [isLoading,setIsLoading] = useState(true)

  //useState is required if we need column filter
  const [column,setColumn] = useState([
    {
      heading: () => '#',
      cell: (row,i) => {
        return i;
      },
      hidden: false,
      width: "20",
      id:1
    },
    {
      heading: () => "Date",
      cell: row => {
        return row['createdAt']
      },
      hidden: false,
      width: "100",
      id:2
    },
    {
      heading: () => "First Name",
      cell: row => row['firstname'],
      hidden: false,
      width: "120",
      id:3
    },
    {
      heading: () => "Last Name",
      cell: row => row['lastname'],
      hidden: false,
      width: "120",
      id:4
    },
    {
      heading: () => "Business Name",
      cell: row => row['businessName'],
      hidden: false,
      width: "120",
      id:5
    },
    {
      heading: () => "Email",
      cell: row => row['email'],
      hidden: false,
      width: "100",
      id:6
    },
    {
      heading: () => "Phone Number",
      cell: row => row['mobile'],
      hidden: false,
      width: "100",
      id:7
    },
    {
      heading: () => "Address",
      cell: row => row['address'],
      hidden: true,
      width: "150",
      id:8
    },
    {
      heading: () => "Country",
      cell: row => row['country'],
      hidden: true,
      width: "100",
      id:9
    },
    {
      heading: () => "State",
      cell: row => row['state'],
      hidden: true,
      width: "100",
      id:10
    },
    {
      heading: () => "City",
      cell: row => row['city'],
      hidden: true,
      width: "100",
      id:11
    },
    {
      heading: () => "Zip Code",
      cell: row => row['zipcode'],
      hidden: true,
      width: "60",
      id:12
    },
    {
      heading: () => {
        return <select
          name="cars"
          id="cars"
          defaultValue={""}
          className="selectBlock cursor"
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}

        >
          <option value="" disabled>Status</option>
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      },
      cell: row => {
        return <>
          {" "}
          <label className="toggle">
            <input type="checkbox"
              name="active_flag"
              defaultChecked={row?.active_flag === "Y"}
              onChange={(ele) => {
                if (ele.target.checked) {
                  handleActiveToggle("Y", row.id);
                  setuserid(row.id)
                } else {
                  handleActiveToggle("N", row.id);
                  setuserid(row.id)
                }

              }}
              value={row.active_flag}
            />
            <span className="slider"></span>
            <span
              className="labels"
              data-on="Active"
              data-off="Inactive"
            ></span>
          </label>{" "}
        </>
      },
      hidden: false,
      width: "50",
      id:13
    },
    {
      heading: () => "Actions",
      cell: row => {
        return <img
          src={actionIcon}
          className="cursorPointer"
          alt="actions"
          onClick={() => {
            setViewToggle(true);
            setuserid(row.id)
          }}
        />
      },
      hidden: false,
      width: "20",
      id:14
    }

  ])

  //required to get the filtered column
  let filterOptions = []
  //default checked values of select options
  let filterDefaultValue = [{value:1},{value:2},{value:3},{value:4},{value:5},{value:6},{value:7},{value:13},{value:14}]

  //generate the options of multi select
  column.forEach((e)=>{
    let option = {
      label:e.id===13?'Status':e.heading(),
      value:e.id
    }
    filterOptions.push(option)
  })

  //to handle the change of the react-multi select
  const handleFilterSelection = (options)=>{
    let newColumn = [...column]
    for(let i=0;i<newColumn.length;i++)
    {
        let data = options.filter((e)=>newColumn[i].id === e.value)
        data.length===1?newColumn[i].hidden = false:newColumn[i].hidden = true
    }
    setColumn(newColumn)
  }

  const handleActiveToggle = async (status, id) => {
    try {
      let res = await AxiosService.post(`/admin/toggle`, {
        "userid": `${id}`,
        "togglestate": status === 'Y' ? true : false
      })
      if (res.status === 200) {
        handleGetBusinessOwner()
        toast.success(res.data.message)
      }
    } catch (error) {
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
    }
  }

  const handleGetBusinessOwner = async () => {
    try {
      setIsLoading(prev=>!prev)
      let res = await AxiosService.post('/business-owners/all', {
        pgno: currentPage,
        limit: itemsPerPage,
        status: statusFilter,
        searchKey
      })

      if (res.data.statusCode === 200) {
        setUsers(res.data.users)
        setTotalCount(res.data.count)
        setIsLoading(prev=>!prev)
      }
    } catch (error) {
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
      setIsLoading(prev=>!prev)
    }
  }
  /* eslint-disable */
  useEffect(() => {
    if (!addToggle && !viewToggle)
      handleGetBusinessOwner()
  }, [addToggle, viewToggle, currentPage, itemsPerPage, searchKey, statusFilter])
  /* eslint-enable */
  return (
    <>
      <div className="adminContentSection">
        <div className="displayFlex alignCenter spaceBetween">
          <div className="titleText "> Business Owner Management</div>
          <div className="flexSection">
            <div className="displayFlex alignCenter spaceBetween gap15">
              <button
                className="blackbuttonoutline"
                onClick={() => setAddToggle(true)}
              >
                <div>Add Business Owner </div>
                <div>
                  <img src={plusIcon} alt="plus" />
                </div>
              </button>
            </div>
          </div>
        </div>
        {isLoading?<PaginatedTable
          row={users}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          isFilterHeader={true}
          filterOptions={filterOptions}
          handleFilterSelection={handleFilterSelection}
          filterDefaultValue={filterDefaultValue}
        />:<div style={{textAlign:"center"}}>Loading...</div>}
      </div>


      {addToggle ? (
        <>
          <AddBusinessOwner addToggle={setAddToggle} />
        </>
      ) : (
        <></>
      )}
      {viewToggle ? (
        <>
          <BusinessOwner viewToggle={setViewToggle} id={userid} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default BusinessOwnerManagement;
