import React,{useState} from 'react'
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import AxiosService from '../../service/ApiService';
import StripeCheckout from './StripeCheckout';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

function Stripe({amount,currency}) {
    const options = {
        mode: 'payment',
        amount,
        currency,
      };
  return <>
    <Elements stripe={stripePromise} options={options}>
        <StripeCheckout currency={currency} amount={amount}/>
    </Elements>
  </>
}

export default Stripe