import React, { useState, useEffect } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import Copyright from './common/Copyright';
import Banner from '../../images/landingPage/donate/banner.jpg';
import Logo from '../../images/landingPage/blackLogo.svg';
import styles from '../../components/landingpage/Donate.module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DonateOnce } from './DonateOnce';
import { Recurring } from './Recurring';

export const Donate = () => {
    const [key, setKey] = useState('donate');
    useEffect(() => {
        AOS.init();
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <section className={`${styles.section1} container-fluid`}>
                <div className={`container py-5`}>
                    <div className='row'>
                        <div className='col-sm-8 offset-sm-2 col-xs-12 text-center pb-5'>
                            <h3 className='fs--32 lh--45 fw--700 fontMontserrat' data-aos="fade-up">Thank you! </h3>
                            <p className='fs--24 lh--45 text-black fontMontserrat fw--600' data-aos="fade-up">Your donations will help to empower more businesses and make an impact in the lives of individuals and communities.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className={`col-sm-10 offset-sm-1 col-xs-12 ${styles.DonateContainer}`}>
                            <div className='row'>
                                <div className='col-sm-6 col-xs-12'>
                                    <div className={`${styles.left}`}>
                                        <img src={Banner} alt='Banner' className='w-100 h-auto' />
                                        <div className={`${styles.donateText} ms-4 bg-black d-flex align-items-center my-3`}>
                                            <div className='bg-white me-4 rounded-circle'>
                                                <img src={Logo} alt='logo' width="60" height="60" className='p-2' />
                                            </div>
                                            <span className='fs--20 text-white fw--600 fontMontserrat'>Donations Via Transfer</span>
                                        </div>
                                        <div className='p-4'>
                                            <p className='fs--16 fw--700'>International (All Over World)</p>
                                            <p><span className='text--white3'>Bank Name :</span> ICICI</p>
                                            <p><span className='text--white3'>Account number:</span> 12345678901</p>
                                            <p><span className='text--white3'>Account Type:</span> Saving</p>
                                        </div>

                                        <div className='p-4'>
                                            <p className='fs--16 fw--700'>Domestic (Nigerian)</p>
                                            <p><span className='text--white3'>Bank Name :</span> </p>
                                            <p className='mb-0'><span className='text--white3'>Account number:</span> </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-6 col-xs-12 ${styles.right}`}>
                                    <div className={`text-center p-4`}>
                                        <p className='fs--25 fw--600'>Choose amount to Donate</p>
                                        <Tabs
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                            className={`tabs mb-3`}
                                        >
                                            <Tab eventKey="donate" title="Donate Once">
                                                <DonateOnce/>
                                            </Tab>
                                            <Tab eventKey="recurring" title="Recurring">
                                                <Recurring/>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
            <Copyright />
        </>
    )
}
