import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import plusIcon from "../../../images/plus-icon.svg";
import editIcon from "../../../images/edit.png";
import deleteIcon from "../../../images/delete.png";
import DeleteModel from '../DeleteModel';
import PaginatedTable from "../../common/PaginatedTable";
import { useLogout } from "../../../hooks/useLogout";
import { toast } from 'react-toastify';
import AxiosService from '../../../service/ApiService';

function WatchLatestSection() {
  const navigate = useNavigate()
  const [isDelete, setIsDelete] = useState(false)
  const [deleteId, setdeleteId] = useState()
  let [isLoading,setIsLoading] = useState(true)
  const [eventId, seteventId] = useState()
  let Logout = useLogout()
  let [toggle, setToggle] = useState(false)
  let [statusFilter, setStatusFilter] = useState("All");
  let [totalCount, setTotalCount] = useState(0)
  let [currentPage, setCurrentPage] = useState(1)
  let [itemsPerPage, setItemsPerPage] = useState(10)
  let [searchKey, setSearchKey] = useState(null)
  let [events, setEvents] = useState([]);

  //toggle status change
  const toggleChange =  async (status, id) => {
  
    try{
      let values = {
        "id" : `${id}`,
        "togglestate" : status === 'Y' ? true : false
      }


      
      let {data} = await AxiosService.post('/content-management-tool/watchlatest/toggle', values)

      if(data.statusCode === 200){
     
        handleGetAllWatchLatest()

        toast.success(data?.message)
      }
    }catch(error){
      console.log(error)
      toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
    }
  }
  const handleDelete = async (id) => {
    try{
      let {data}  = await AxiosService.delete(`/content-management-tool/watchlatest/delete/${id}`)
      if(data.statusCode === 200){
        setIsDelete(false)
        handleGetAllWatchLatest()

        toast.success(data?.message)
      }
    }catch(error){
      if (error.response.status === 401)
      Logout()
    toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
  
    }
  }
  const [column,setColumn] = useState([
    {
      heading: () => '#',
      cell: (row,i) => {
        return i;
      },
      hidden: false,
      width: "20",
      id:1
    },
    {
      heading: () => "Date",
      cell: row => {
        return row['date']
      },
      hidden: false,
      width: "100",
      id:2
    },
    {
      heading: () => "Text",
      cell: row => {
        return <div dangerouslySetInnerHTML={{ __html: row['text'] }}></div> 
      },
      hidden: false,
      width: "100",
      id:3
    },
    
    {
      heading: () => "Image/Video",
      cell: row => {
        return <>
        {row['videoUrl'] !== null ? 
        <video className="br--5"
                        width="125"
                        height="100"
                        controls
                        src={row['videoUrl']}
                      >
                        Your browser does not support the video tag.
                      </video> : 
              <img

              className="br--5"
    
              src={`${row['imageUrl']}`}
    
              alt='announcementUrl'
    
              style={{
    
    
    
                backgroundRepeat: "no-repeat",
    
               
    
               width: "124px",
                border: "1px solid #ff7f00",
    
                height: "100px",
                objectFit: "cover",
    
              }}
    
    
    
            />}
        </>

      },
      hidden: false,
      width: "100",
      id:4
    },
    {
      heading: () => "Link",
      cell: row => {
        return  <>
        {row['videoUrl'] !== null ?
          <a href = {`${row['videoRedirectUrl']}`} target='_blank'>Click Here </a> :
          <a href = {`${row['imageRedirectUrl']}`} target='_blank'>Click Here </a>   
        }
        </>
      },
      hidden: false,
      width: "100",
      id:4
    },
 
    {
      heading: () => {
        return <select
          name="cars"
          id="cars"
          defaultValue={""}
          className="selectBlock cursor"
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}

        >
          <option value="" disabled>Status</option>
          <option value="All">All</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      },
      cell: row => {
        return <>
          {" "}
          <label className="toggle">
            <input type="checkbox"
              name="active_flag"
              defaultChecked={row?.active_flag === "Y"}
              onClick={(e) => {
                                
                if (e.target.checked) {
                  seteventId(row?.id)
                  toggleChange("Y", row?.id )
                } else {
                  seteventId(row?.id)
                  toggleChange("N", row?.id )
                }

              }}
              value={row.active_flag}
            />
            <span className="slider"></span>
            <span
              className="labels"
              data-on="Active"
              data-off="Inactive"
            ></span>
          </label>{" "}
        </>
      },
      hidden: false,
      width: "50",
      id:5
    },
    {
      heading: () => "Actions",
      cell: row => {
        return<>
        <img
          src={editIcon}
          className="cursorPointer"
          alt="actions"
          onClick={() => {
            navigate(`/admin/watch-latest-section/edit/${row.id}`)
          
          }}
        />
                <button className="bg-white border-0" title='Delete' onClick={() => {
          
          setIsDelete(true)
          setdeleteId(row.id)
        }}>
          <img
            src={deleteIcon} alt='Delete'
          /></button>
        </> 

      },
      hidden: false,
      width: "20",
      id:6
    }

  ])

  const handleGetAllWatchLatest = async () =>{
    try{
      setIsLoading(prev=>!prev)
      let {data}  = await AxiosService.post('/content-management-tool/watchlatest/get-all', {
        pgno: currentPage,
        limit: itemsPerPage,
        status: statusFilter,
        searchKey
      })
      
      if (data.statusCode === 200) {
        setEvents(data.watchLatests)
        setTotalCount(data.count)
        setIsLoading(prev=>!prev)
      }

    }catch(error){
      if (error.response.status === 401)
      Logout()
    toast.error(error.response.data.message ? error.response.data.message : error.response.data.message[0]);
    setIsLoading(prev=>!prev)
  
    }
  }
  useEffect(() => {
    handleGetAllWatchLatest()
  }, [currentPage, itemsPerPage, searchKey, statusFilter, toggle, eventId ])

  return <>
    <div className="adminContentSection">
      <div className="titleSection">
        <div className="titleText "> Watch latest section</div>
        <div className="flexSection">
          <div className="displayFlex alignCenter spaceBetween gap15">

            <button onClick={() => {
              navigate('/admin/watch-latest-section/add-watch')
            }}
              className="blackbuttonoutline"
            >
              <div>Add Watch latest </div>
              <div>
                <img src={plusIcon} alt="plusicon" />
              </div>
            </button>
          </div>
        </div>
      </div>

      {isLoading?<PaginatedTable
          row={events}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
        />:<div style={{textAlign:"center"}}>Loading...</div>}
    </div>


    {
          isDelete ? <DeleteModel isDelete={isDelete} setIsDelete={setIsDelete} section="watchlatest" id={deleteId} handleDelete={handleDelete}/> : <></>
  }


  </>
}

export default WatchLatestSection